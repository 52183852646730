// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  apiUrl: 'https://apistaging.riloop.com/graphql',
  cmsApiUrl: 'https://cmsstaging.riloop.com/graphql',
  baseUrl: 'https://staging.riloop.com',
  internalFrontendUrl: 'http://frontend:80',
  crawlTimeout: {"free":10, "pro":30, "max":60},
  searchResultLimit: {"unregistered":100, "free":200, "pro":500, "max":1000},
  mapsEmbedApiKey: 'AIzaSyCA37DaxrWeQuZ0RbMoDUL2QAZie14Pd5A',
  googleClientId: '411779435399-ai8dsletedkiretc3j066oosoqoo3129.apps.googleusercontent.com',
  siteName: 'Riloop'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
